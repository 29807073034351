import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {
  DataGridPro,
  UncapitalizedGridProSlotsComponent,
} from '@mui/x-data-grid-pro';
import {Box, Typography} from '@mui/material';
import {
  GridColDef,
  GridPaginationModel,
  GridRenderCellParams,
  GridSortModel,
} from '@mui/x-data-grid';
import {IMediaTrack} from '../../dto/MediaTrack.dto';
import {DataGridCustPagination} from '../utlis/DataGrid/Pagination';
import MusicPreviewBtn, {MusicPreviewBtnDisable} from './MusicPreviewBtn';
import {
  defaultDataGridProSearchSort,
  IDataGridProSearchSort,
} from '../../dto/Html.dto';
import {useStoreSelector} from '../../store/configstore';
import {companyIdSelector} from '../../store/Slices/companySlice';
import {
  useRqMediaTracksPaginate,
  useRqMediaTracksWithoutCompanyPaginate,
} from '../../react_query/media_tracks/ListMediaTrackPaginatPro';
import InputSearch from '../styles/InputSearch';
import {NoPlayInfoButton} from '../../pages/admin/no-play/NoPlayInfoButton';
import {SongSelectionRemoveButton} from '../Button/SongSelectionRemoveButton';
import {SongSelectionAddButton} from '../Button/SongSelectionAddButton';
import {GridDurationColumn} from '../grid/GridColumns';
import {IStation} from '../../dto/Station.dto';

type Props = {
  selectedTracks: IMediaTrack[];
  onSelect: (track: IMediaTrack) => void;
  onDeselect: (track: IMediaTrack) => void;
  isAddDisabled?: boolean;
  company_id?: number;
  slots?: Partial<UncapitalizedGridProSlotsComponent>;
  title?: string;
  selectedStations?: IStation[];
  type?: string;
  musicType?: string;
  isTrackWithoutGenreRestriction?: boolean;
  selection_type?: string;
};

const MusicSelectionTable = ({
  selectedTracks,
  onSelect,
  onDeselect,
  isAddDisabled,
  company_id,
  musicType,
  slots = {
    pagination: DataGridCustPagination,
  },
  title,
  selectedStations,
  type,
  isTrackWithoutGenreRestriction,
  selection_type,
}: Props) => {
  const [selectedTrackIds, setSelectedTrackIds] = useState<number[]>([]);
  const [trackDetails, setTrackDetails] = useState<IMediaTrack[]>();
  const [query, setQuery] = React.useState<IDataGridProSearchSort>({
    ...defaultDataGridProSearchSort,
    ...(selectedStations && {station_id: selectedStations.map(val => val.id)}),
    ...(type && {type: type}),
    ...(selection_type && {selection_type: selection_type}),
  });

  const companySelector = useStoreSelector(companyIdSelector);
  const companyId = company_id ?? companySelector;
  const {data, isLoading} = useRqMediaTracksWithoutCompanyPaginate(
    query,
    isTrackWithoutGenreRestriction,
  );
  const {data: mediaTrackData, isLoading: mediaTrackIsLoading} =
    useRqMediaTracksPaginate(query, companyId, !isTrackWithoutGenreRestriction);
  useEffect(() => {
    if (mediaTrackData) {
      setTrackDetails(mediaTrackData.data);
    } else if (data) {
      setTrackDetails(data.data);
    }
  }, [data, mediaTrackData]);
  const handleSearch = useCallback((newValue: string) => {
    setQuery(prev => ({
      ...prev,
      search: newValue,
    }));
  }, []);
  const handlePagination = useCallback((paginate: GridPaginationModel) => {
    setQuery(prev => ({...prev, ...paginate}));
  }, []);

  const handleSortModelChange = (model: GridSortModel) => {
    setQuery(prev => ({...prev, sort: model}));
  };

  const handleSongAdd = useCallback(
    (musicTrack: IMediaTrack) => {
      onSelect(musicTrack);
    },
    [onSelect],
  );

  const handleSongRemove = useCallback(
    (musicTrack: IMediaTrack) => {
      onDeselect(musicTrack);
    },
    [onDeselect],
  );

  const columns: GridColDef[] = useMemo(
    () =>
      [
        {
          field: 'track_artist',
          headerName: 'Artist',
          flex: 1,
        },
        {
          field: 'track_name',
          headerName: 'Song Title',
          flex: 1,
        },
        GridDurationColumn,
        {
          field: 'id',
          type: 'actions',
          headerName: 'Play',
          width: 54,
          renderCell: (params: GridRenderCellParams<IMediaTrack>) =>
            params.row.track_category === 5 ? (
              <MusicPreviewBtnDisable />
            ) : params.row.url ? (
              <MusicPreviewBtn
                id={params.row.id}
                url={params.row.url}
                track_name={params.row.track_name}
                track_artist={params.row.track_artist}
              />
            ) : null,
        },
        {
          type: 'actions',
          field: 'actions',
          headerName: 'Action',
          hideable: false,
          width: 125,
          renderCell: (params: GridRenderCellParams<IMediaTrack>) =>
            params.row.track_category === 5 ? (
              <NoPlayInfoButton id={params.row.id} />
            ) : selectedTrackIds.includes(params.row.id) ? (
              <SongSelectionRemoveButton
                onClick={() => {
                  handleSongRemove(params.row);
                }}
              />
            ) : (
              <SongSelectionAddButton
                onClick={() => {
                  handleSongAdd(params.row);
                }}
                disabled={isAddDisabled}
              />
            ),
        },
      ].map(column => ({
        ...column,
        sortable: false,
        filterable: false,
      })),
    [handleSongAdd, handleSongRemove, isAddDisabled, selectedTrackIds],
  );
  useEffect(() => {
    setSelectedTrackIds(selectedTracks.map(o => o.id));
    if (musicType) {
      setQuery(prev => ({
        ...prev,
        type: musicType,
      }));
    }
  }, [selectedTracks, musicType]);

  return (
    <Box className='w-full h-full flex-column justify-start'>
      <Box className='w-full h-62 flex flex-row justify-between items-center'>
        <Typography variant='h5' className='text-white font-bold'>
          {title ? title : 'Select Songs'}
        </Typography>
        <InputSearch
          className='w-347'
          placeholder='Search Song'
          value={query.search || ''}
          onChange={handleSearch}
        />
      </Box>
      <Box className='h-480 max-h-480 scroll-y' sx={{width: '99%'}}>
        <DataGridPro
          rows={trackDetails ?? []}
          columns={columns}
          loading={
            isTrackWithoutGenreRestriction ? isLoading : mediaTrackIsLoading
          }
          rowCount={
            isTrackWithoutGenreRestriction ? data?.total : mediaTrackData?.total
          }
          pageSizeOptions={[10, 25, 50]}
          paginationModel={{page: query.page, pageSize: query.pageSize}}
          paginationMode='server'
          pagination={true}
          onPaginationModelChange={handlePagination}
          onSortModelChange={handleSortModelChange}
          density='compact'
          disableRowSelectionOnClick
          disableColumnMenu
          slots={slots}
          slotProps={{
            columnsPanel: {
              disableHideAllButton: true,
              disableShowAllButton: true,
            },
          }}
        />
      </Box>
    </Box>
  );
};

export default MusicSelectionTable;
